import React from 'react';
import GenericPage from '../components/modules/generic/genericPage';
import NoiseWrapper from '../components/modules/wrappers/NoiseWrapper';

const AvisoPrivacidad = () => {
  const title = 'Aviso de Privacidad';
  const subTitle = 'Conoce más sobre nuestro aviso de privacidad';
  return (
    <NoiseWrapper>
      <GenericPage title={title} subTitle={subTitle} />
    </NoiseWrapper>
  );
};

export default AvisoPrivacidad;
